// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-md": () => import("../src/pages/404.md" /* webpackChunkName: "component---src-pages-404-md" */),
  "component---src-pages-en-contacts-md": () => import("../src/pages/en/contacts.md" /* webpackChunkName: "component---src-pages-en-contacts-md" */),
  "component---src-pages-en-country-furnitures-md": () => import("../src/pages/en/country-furnitures.md" /* webpackChunkName: "component---src-pages-en-country-furnitures-md" */),
  "component---src-pages-en-index-md": () => import("../src/pages/en/index.md" /* webpackChunkName: "component---src-pages-en-index-md" */),
  "component---src-pages-en-our-method-md": () => import("../src/pages/en/our-method.md" /* webpackChunkName: "component---src-pages-en-our-method-md" */),
  "component---src-pages-en-products-md": () => import("../src/pages/en/products.md" /* webpackChunkName: "component---src-pages-en-products-md" */),
  "component---src-pages-en-where-we-are-md": () => import("../src/pages/en/where-we-are.md" /* webpackChunkName: "component---src-pages-en-where-we-are-md" */),
  "component---src-pages-index-md": () => import("../src/pages/index.md" /* webpackChunkName: "component---src-pages-index-md" */),
  "component---src-pages-it-contatti-md": () => import("../src/pages/it/contatti.md" /* webpackChunkName: "component---src-pages-it-contatti-md" */),
  "component---src-pages-it-dove-siamo-md": () => import("../src/pages/it/dove-siamo.md" /* webpackChunkName: "component---src-pages-it-dove-siamo-md" */),
  "component---src-pages-it-metodo-md": () => import("../src/pages/it/metodo.md" /* webpackChunkName: "component---src-pages-it-metodo-md" */),
  "component---src-pages-it-mobili-per-agriturismo-md": () => import("../src/pages/it/mobili-per-agriturismo.md" /* webpackChunkName: "component---src-pages-it-mobili-per-agriturismo-md" */),
  "component---src-pages-it-privacy-policy-md": () => import("../src/pages/it/privacy-policy.md" /* webpackChunkName: "component---src-pages-it-privacy-policy-md" */),
  "component---src-pages-it-tranciati-in-legno-md": () => import("../src/pages/it/tranciati-in-legno.md" /* webpackChunkName: "component---src-pages-it-tranciati-in-legno-md" */)
}

